import axios from 'axios';
import { BUILDER_URL } from '../../utils/config'; // Adjust the import path as necessary
import * as Sentry from '@sentry/react';

const BASE_URL = BUILDER_URL;

const errorLogApi = ({ job_id, endpoint, payload, responseData, errorMsg }) => {
  const payLoad = JSON.stringify(payload) ;
  const error = JSON.stringify(errorMsg);
  // job_id = JSON.stringify(job_id);
  // responseData = JSON.stringify(responseData);
  //console.log('--------------------')
  //console.log('global function for er handling')
  //console.log('jboId', job_id);
  //console.log('endpoint', endpoint);
  //console.log('payload', payload);
  //console.log('responseData', responseData);
  //console.log('errorMsg', errorMsg);
  //console.log('--------------------')

  const fullPayload = {
    job_id,
    endpoint,
    payLoad,
    responseData,
    error,
  };

  const resp = {
    responseData: JSON.stringify(fullPayload)
  }

  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
    } else {
      axios.post(`${BASE_URL}/error-log`, resp)
      .then(response => {
        if (response.status === 200) { 
          console.log('error log api success');
          console.log('Success response', response.data);
        }
        
      })
      .catch(error => {
        Sentry.captureException(error);
      });
    }
}
};

export default errorLogApi;