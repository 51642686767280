import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAxios } from "../utils/axios";
export const CartContext = createContext();
import { ThemeContext } from '../contexts/ThemeContext';
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { useJobId } from '../contexts/ContextJbo';
import errorLogApi from '../components/custom/ErrorLogApi';
import CustomPopup from '../components/CustomPopup';

/**
 * CartProvider component to manage the cart's state and provide it to the rest of the application.
 * 
 * This component handles fetching, updating, and removing items from the cart. It also manages 
 * the cart count and total price. The component uses the UserContext for user details and
 * Axios for API calls.
 * 
 * @param {React.ReactNode} children - The child components of CartProvider.
 */

export const CartProvider = ({ children }) => {
  // State management for cart and related properties
  const [cart, setCart] = useState([]);
  const theme = useContext(ThemeContext);
  const [cartCount, setCartCount] = useState(0);
  const [cartTotal, setCartTotal] = useState({});
  const [TotalPrice, setTotalprice] = useState("");
  const [promoMessageData, setPromoMessageData] = useState("");
  // Previleg cards states - 27/08/2024
  const [previlegeCardStatus,setPrevilegeCardStatus]=useState(2);
  const [previlegeCardData,setPrevilegeCardData]=useState(2);
  const [previlegeCardValue,setPrevilegeCardValue]=useState("");
  const [promoStatus, setPromoStatus] = useState(4);
  const [promoStatusData, setPromoStatusData] = useState();
  const [TotalAmount, setTotalAmount] = useState("");
  const [TotalQuantity, setTotalQuantity] = useState("");
  const [TaxAmount, setTaxAmount] = useState("");
  const [Totaldiscount, setTotalDiscount] = useState();
  const [cartRender, setCartRender] = useState(true); // Flag to trigger re-render
  const [loading, setLoading] = useState(true); // Flag to trigger re-render
  const [errorstate, setErrorstate] = useState(false);
  const axios = useAxios();
  const { userId, token } = useContext(UserContext);
  const { currentCurrency } = useContext(CurrencyContext); // new line
  // State to manage Snackbar visibility
  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [cartResponse, setCartResponse] = useState("");
  const { jboId } = useJobId();
  const [isShowPayOverCounter, setIsShowPayOverCounter] = useState('');
  const [isShowPartialPayment, setIsShowPartialPayment] = useState(null);
  const [isShowPickup, setIsShowPickup] = useState(null);
  const [isShowOldGold, setIsShowOldGold] = useState(null);
  // Popup state
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupConfig, setPopupConfig] = useState({});

  const openPopup = (config) => {
    setPopupConfig(config);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  // Fetches the user's cart
  const fetchCart = async () => {
    // Previleage card modifications - 28/08/2024
    var promoStatus = sessionStorage.getItem("promoStatusCheck");
    var prevStatus = sessionStorage.getItem("prev_status_check");
    
    var promoCodeData = promoStatus == 1 ? sessionStorage.getItem("promocode") : "";
    var previlegeValue = prevStatus == 1 ?  sessionStorage.getItem("previlageCardvalue") : "";
    
    // Determine the value to send for promo_code - 28/08/2024
    var promoCodeValue = promoStatus == 1 ? promoCodeData : (prevStatus == 1 ?  previlegeValue : "");
 
    console.log("promoCodeData", promoCodeData);
    try {
      const payload = {
        user_id: userId,
        token: token,
        // flag added for special price - 18/09/2024
        checkout:0,
        promo_code: promoCodeValue,
        privilege_card_status: prevStatus ? 1 : 2
      };
      const response = await axios.post('product/getCartProduct', payload);
      if (response.status === 200) {
        setLoading(false);
        setCart(response.data.product);
        setCartTotal(response.data.total);
        // previlege card values get from api - 27/08/2024
        setPrevilegeCardStatus(response.data.privilege_card_status)
        sessionStorage.setItem('previlageCardvalue',response.data.privilege_card);
        // setPrevilegeCardValue( response.data.privilege_card);
        setTotalAmount(response.data.taxable_amount);
        setTotalQuantity(response.data.total_quantity);
        setTotalDiscount(response.data.total_discount);
        setTaxAmount(response.data.tax_amount);
        setTotalprice(response.data.total_price);
        setIsShowPayOverCounter(response.data.pay_over_counter_enable)
        setIsShowPartialPayment(response.data.multipayment_enable)
        setIsShowOldGold(response.data.og_value_enable)
        setIsShowPickup(response.data.pickup_enable)
        const productIds = response.data.product.map(product => product.product_id);
        // Check if the promo code was previously applied successfullysd
        console.log("promoStatus", promoStatus);

        
      }
      setErrorstate(false);
    } catch (error) {
      setErrorstate(true);
      const jboID = jboId;
      const endpoint = 'product/getCartProduct';
      const payLoad = {
        user_id: userId,
        token: token,
      };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg,
      });
      setLoading(false);
    }
  };

  // Fetches the count of items in the user's cart
  const fetchCartCount = async () => {
    try {
      const response = await axios.post('user/userCartCount', { user_id: userId });
      // zero cart issue fixed - 26/08/2024
      if (response.data && response.data.count) {
        setCartCount(response.data.count);
      }else{
        setCartCount(0);
      }
    } catch (error) {
      const jboID = jboId;
      const endpoint = 'user/userCartCount';
      const payLoad = { user_id: userId };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg,
      });
    }
  };
  console.log("previlege",previlegeCardValue);
  // Effect to fetch cart and cart count when userId, token, or cartRender changes
  useEffect(() => {
    if (userId && userId !== 'null' && token && theme.siteStatus === 1) { // Only fetch cart if userId and token are set
      // fetchcart called - 26/08/2024
     fetchCart();
      fetchCartCount();
    } else {
      // If userId or token is null (i.e., user is logged out), clear the cart
      setCart([]);
      setCartTotal({});
    }
  }, [userId, token]);  // Trigger the effect only when dependencies updated - 28/08/2024

  // Adds a product to the cart
  const addToCart = async (product) => {
    const payload = {
      user_id: userId,  // replace with actual user ID
      token: token, // this should be dynamic
      "product_id": product.product_id ? product.product_id : product.id,
      "quantity": 1,
    };
    try {
      const response = await axios.post('user/setaddtocart', payload);
      // snackbar bar component updated for already in cart - 29/06/2024
      setSnackbarVisible(true); // Show snackbar on success
      setCartResponse(response.data.message);
      if (response.data.status === 1) {
        fetchCart();
        fetchCartCount();
        setCartRender(!cartRender);
      
      }
    } catch (error) {
      const jboID = jboId;
      const endpoint = 'user/setaddtocart';
      const payLoad = {
        user_id: userId,
        token: token,
        "product_id": product.product_id ? product.product_id : product.id,
        "quantity": 1,
      };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg,
      });
    }
  };

  // Updates the quantity of a product in the cart
  const updateQuantity = (product, quantity) => {
    setCart(cart.map(item => item.id === product.id ? { ...item, quantity: quantity } : item));
  };

  // Removes a product from the cart
  const removeFromCart = async (product) => {
    const payload2 = {
      user_id: userId, // replace with actual user ID
      product_id: product.id, // use product_id instead of id
    };

    openPopup({
      type: 'warning',
      title: 'Are you sure?',
      subText: 'You want to delete this item from your cart?',
      confirmLabel: "Yes, remove",
      cancelLabel: "No, keep it",
      showCloseIcon:false,
      onConfirm: async () => {
        try {
          const response = await axios.post('user/removeCart', payload2);
          if (response.data.status == 1) {
            fetchCart();
            fetchCartCount();
            openPopup({
              type: 'success',
              title: 'Deleted!',
              subText: 'The item has been removed from your cart.',
              onClose: closePopup, // Ensure this is passed
              autoClose: 2, // Auto close after 2 seconds
            });
          } else {
            throw new Error("Failed to remove from Cart");
          }
        } catch (error) {
          fetchCart(); // refetch cart on error to revert changes
          fetchCartCount();
          const jboID = jboId;
          const endpoint = 'user/removeCart';
          const payLoad = {
            user_id: userId, // replace with actual user ID
            product_id: product.id, // use product_id instead of id
          };
          const responseData = error?.response?.data ? error.response.data : '';
          const errorMsg = error;
          errorLogApi({
            job_id: jboID,
            endpoint: endpoint,
            payload: payLoad,
            responseData: responseData,
            errorMsg: errorMsg,
          });
          openPopup({
            type: 'error',
            title: 'Error!',
            subText: 'Failed to remove the item from your cart.',
            onClose: closePopup, // Ensure this is passed
            autoClose: 3,
          });
        }
      },
      onClose: closePopup, // Ensure this is passed
    });
  };

  // Providing the cart context to child components updated for previlage cards - 27/08/2024
  return (
    <CartContext.Provider value={{
      promoMessageData, setLoading, setTotalDiscount,previlegeCardStatus,setPrevilegeCardData,previlegeCardData,previlegeCardValue,promoStatus,setPromoStatus, promoStatusData, Totaldiscount, TotalQuantity, loading, TaxAmount, TotalAmount, cartCount, cart, cartTotal, TotalPrice, fetchCartCount, cartResponse, fetchCart, addToCart, updateQuantity, removeFromCart, isSnackbarVisible, errorstate,isShowPayOverCounter,isShowPartialPayment,isShowOldGold,isShowPickup
    }}>
      {children}
      <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={popupConfig.onConfirm}
        onClose={popupConfig.onClose}
        autoClose={popupConfig.autoClose}
        confirmLabel={popupConfig.confirmLabel}
        cancelLabel={popupConfig.cancelLabel}
        showCloseIcon={popupConfig.showCloseIcon}
      />
    </CartContext.Provider>
  );
};
